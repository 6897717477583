<template>
	<div class="login-container">
		<div class="login-left flex-1 h-100">
			<div class="w-100 text-white flex-all position-absolute" style="bottom: 35px;font-size: 18px;">
				<!-- 安徽九猫信息技术有限公司©2024版权所有 -->
			</div>
		</div>
		<div class="login h-100">
			<div class="login-right">
				<el-form ref="loginForm" :model="loginForm" :rules="loginRules" label-width="0" class="login-box" size="large"  @submit.native.prevent>
					<div class="login-header mb-5">
					  <p class="fs_32 font-bold text-white text-center letter-s-2">欢迎登录</p>
					  <p class="fs_24 font-bold text-white text-center mt-3 letter-s-2">{{config.system_name || '管理系统'}}</p>
					</div>
					<el-form-item prop="username">
					  <el-input placeholder="账号" prefix-icon="el-icon-user-solid" v-model="loginForm.username" autocomplete="off" size="medium">
					  </el-input>
					</el-form-item>
					<el-form-item prop="password">
					  <el-input :type="pwdType" placeholder="密码" prefix-icon="el-icon-lock" v-model="loginForm.password" autocomplete="off" size="medium">
						<i slot="suffix" :class="['el-input__icon icon cursor-pointer',suffixIcon]" @click="showPwd"></i>
					  </el-input>
					</el-form-item>
					<!-- <el-checkbox v-model="rememberPwd">记住密码</el-checkbox> -->
					<el-form-item>
					  <el-button type="primary" class="w-100 fs_18 mt-3" native-type="submit" @click="onSubmit()" style="background-color: #197FE7;font-size: 18px;">登 录</el-button>
					</el-form-item>
				</el-form>
			</div>
		</div>
	</div>
</template>

<script>
	import { mapState } from "vuex"
	export default{
		data() {
			const validatePassword = (rule, value, callback) => {
			  if (value.length < 6) {
				callback(new Error('请填写6位数以上密码'))
			  } else {
				callback()
			  }
			}
			return {
				loading: false,
				configName:'',
				pwdType:"password",
				suffixIcon:'icon-eye2',
				rememberPwd:true,
				loginForm:{
					username:'',
					password:'',
				},
				loginRules: {
					username: [{ required: true, message: '请输入您的账号', trigger: 'blur' }],
					password: [{ required: true, trigger: 'blur', validator: validatePassword }]
				},
			}
		},
		computed:{
			...mapState({
			  config:state=>state.common.config,
			}),
		},
		methods:{
			showPwd(){
				   this.pwdType === 'password' ? this.pwdType = '' : this.pwdType = 'password';
				   this.pwdType == '' ? this.suffixIcon = 'icon-eye-blocked2' : this.suffixIcon = 'icon-eye2';
			},
			onSubmit(){
				this.$refs.loginForm.validate(valid => {
				        if (valid) {
						    this.$axios.post('/manage/login', this.loginForm).then((res) => {
								if(res.status){
									// 存值给cookies
									this.VueCookies.set('token', res.data.token,{expires:7});
									this.VueCookies.set('userId', res.data.id,{expires:7});
									this.VueCookies.set('userName', res.data.username,{expires:7});
									this.VueCookies.set('avatar', res.data.url,{expires:7});
									this.VueCookies.set('roles', JSON.stringify(res.data.roles),{expires:7});
									//跳转
									var redirect = this.$route.query.redirect;
									if(redirect){
									  this.$router.push(redirect);
									}else{
									  this.$router.push("/");
									}
								}else{
									this.$notify({
									  title: "登录失败",
									  message: res.msg,
									  type: 'warning',
									  duration: 3000,
									});
								}
							})
						} else {
						  console.log('error submit!!')
						  return false
						}
				})
				
			},
		}
	}
</script>

<style scoped lang="scss">
.login-container{
  background-color:#FFFFFF;
  height: 100vh;
  display: flex;
  align-items: center;
  position: relative;
  background: url('../../assets/images/background.jpg') no-repeat;
  background-size: 100% 100%;
  background-position: center;
  .login{
	width: 40%;
  }
  .login-left{
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	// background-color: #235ADA;
	& img{
		width: 652px;
		height: 516px;
	}
  }
  .login-right{
	height: 100%;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: center;
	.login-box{
		width: 400px;
		min-height: 400px;
		margin: 0 auto;
		.login-header{
		  margin-top: 50px;
		}
	}
  }

}
</style>